import React from "react"
import _ from 'lodash';
import Layout from "../components/layout"
import { connect } from "react-redux";
import ReactTable from 'react-table';
import { getGroupModels, getUngroupedGuests } from "../store/selectors/selectors"
import { clearChanges, mergeGroupUpdates, saveGuestGroup, setCurrentGroupUpdates } from "../store/actions/guestActions"
import AssignGuestToGroupModal from "../components/AssignGuestToGroupModal"
import * as classnames from "classnames"

class GroupsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowingAssignmentModal: false,
      selectedGuestGroupId: null,
      filterText: ''
    }
  }

  render() {
      const { selectedGuestGroupId, filterText } = this.state;
      const { groupModels, currentGroupUpdates, ungroupedGuests, location } = this.props;

      const showStatusInformation = location && (location.pathname === '/groups' || _.includes(location.pathname, '/status'));
      const showAddressInformation = location && (location.pathname === '/groups' || _.includes(location.pathname, '/addresses'));

      const hasUngroupedGuests = _.size(ungroupedGuests) > 0;

      const thead = (
        <tr>
          <th style={{ minWidth: 60 }}/>
          <th style={{ minWidth: 350 }}>Guests</th>
          {showStatusInformation && <th style={{ minWidth: 60 }}>STD</th>}
          {showStatusInformation && <th style={{ minWidth: 50 }}>RSVP</th>}
          {showAddressInformation && <th style={{ minWidth: 220 }}>Addressed To</th>}
          {showAddressInformation && <th style={{ minWidth: 250 }}>Address 1</th>}
          {showAddressInformation && <th style={{ minWidth: 100 }}>Address 2</th>}
          {showAddressInformation && <th style={{ minWidth: 150 }}>City</th>}
          {showAddressInformation && <th style={{ minWidth: 70 }}>State</th>}
          {showAddressInformation && <th style={{ minWidth: 90 }}>Zip Code</th>}
          <th style={{ minWidth: 60 }}/>
        </tr>
      );

      const filtered = _.filter(groupModels, ({ guests, addressedTo }) => {
        return _.includes(addressedTo, _.toLower(filterText)) || _.some(guests, ({name}) => _.includes(_.toLower(name), _.toLower(filterText)))
      });

      const sorted = _.sortBy(filtered, ({ guests }) => _.toLower(_.sortBy(_.map(guests, 'name')).join("")));

      return (
            <Layout>
              <div style={{ overflowX: 'auto' }}>
                <input className="form-control my-3" placeholder="Search..." value={filterText || ''} onChange={(e) => this.setState({ filterText: e.target.value })} />
                <table className="table table-bordered table-striped table-hover table-sm">
                  <thead>
                  {thead}
                  </thead>
                  <tbody>
                  {
                    _.map(sorted, (group, i) => {
                      const isDirty = _.has(currentGroupUpdates, group.guestGroupId);

                      const mergedGuest = isDirty ? { ...group, ...currentGroupUpdates[group.guestGroupId] } : group;

                      return (
                        <React.Fragment key={mergedGuest.guestGroupId}  >
                          <tr>
                            <td>
                              {isDirty && <>
                                <button className="btn btn-sm btn-primary" onClick={() => this.saveGuestGroup(mergedGuest.guestGroupId)}>Save</button>
                              </>}
                            </td>
                            <td>
                              {_.map(mergedGuest.guests, ({ name, status }, i) => {
                                const isLast = (_.size(mergedGuest.guests) - 1) === i;
                                return (
                                  <span className={classnames({ 'maybe-guest': status === 2 })}>{name}{isLast ? '' : ', '}</span>
                                );
                              })}
                              {' '}
                              <a href="javascript:void(0);" onClick={() => this.setState({ selectedGuestGroupId: mergedGuest.guestGroupId })}>Modify</a>
                            </td>
                            {showStatusInformation && <td>
                              <input type="checkbox" checked={mergedGuest.saveTheDateSent} onChange={(e) => this.toggleCheckbox('saveTheDateSent', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showStatusInformation && <td>
                              <input type="checkbox" checked={mergedGuest.rsvpSent} onChange={(e) => this.toggleCheckbox('rsvpSent', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showAddressInformation && <td>
                              <input className="form-control input-sm" value={mergedGuest.addressedTo || ''} onChange={(e) => this.setInput('addressedTo', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showAddressInformation && <td>
                              <input className="form-control input-sm" value={mergedGuest.address1 || ''} onChange={(e) => this.setInput('address1', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showAddressInformation && <td>
                              <input className="form-control input-sm" value={mergedGuest.address2 || ''} onChange={(e) => this.setInput('address2', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showAddressInformation && <td>
                              <input className="form-control input-sm" value={mergedGuest.city || ''} onChange={(e) => this.setInput('city', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showAddressInformation && <td>
                              <input className="form-control input-sm" value={mergedGuest.state || ''} onChange={(e) => this.setInput('state', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            {showAddressInformation && <td>
                              <input className="form-control input-sm" value={mergedGuest.zipCode || ''} onChange={(e) => this.setInput('zipCode', mergedGuest.guestGroupId, e)}/>
                            </td>}
                            <td>
                              {isDirty && <>
                                <button className="btn btn-sm btn-primary" onClick={() => this.saveGuestGroup(mergedGuest.guestGroupId)}>Save</button>
                              </>}
                            </td>
                          </tr>

                          {i > 0 && i % 9 === 0 && thead}
                        </React.Fragment>
                      );
                    })
                  }
                  </tbody>
                </table>
              </div>

              {hasUngroupedGuests && <div className="my-2">
                <button className="btn btn-primary" onClick={() => this.setState({ selectedGuestGroupId: 0 })}>Assign {_.size(ungroupedGuests)} Guest(s)</button>
              </div>}

              <AssignGuestToGroupModal
                show={selectedGuestGroupId != null}
                selectedGuestGroup={selectedGuestGroupId > 0 ? _.find(groupModels, { guestGroupId: selectedGuestGroupId }) : selectedGuestGroupId === 0 ? {} : null}
                ungroupedGuests={ungroupedGuests}
                mergeGroupUpdates={this.props.mergeGroupUpdates}
                hide={() => this.setState({ selectedGuestGroupId: null })}>
              </AssignGuestToGroupModal>
          </Layout>
      )
    }

    componentDidMount() {
      document.title = 'Groups';
      this.props.clearChanges();
    }

  toggleCheckbox = (name, guestGroupId, e) => {
    this.props.setCurrentGroupUpdates(guestGroupId, { [name]: e.target.checked });
  };

  setInput = (name, guestGroupId, e) => {
    this.props.setCurrentGroupUpdates(guestGroupId, { [name]: e.target.value });
  };

  saveGuestGroup = (guestGroupId) => {
    const { groupModels, currentGroupUpdates } = this.props;

    const isDirty = _.has(currentGroupUpdates, guestGroupId);
    const guestGroup = _.find(groupModels, { guestGroupId })

    if(!isDirty) {
      return;
    }

    const mergedGuest = { ...(guestGroup || {}), ...currentGroupUpdates[guestGroupId] };
    this.props.saveGuestGroup(mergedGuest);
  }
}

const mapStateToProps = (state) => {
  const { guestsGroups, guestsGroupXrefs, currentGroupUpdates } = state;
  return {
    ungroupedGuests: getUngroupedGuests(state),
    groupModels: getGroupModels(state),
    guestsGroups: guestsGroups,
    guestsGroupXrefs: guestsGroupXrefs,
    currentGroupUpdates: currentGroupUpdates,
  };
};

const mapDispatchToProps = {
  clearChanges: clearChanges,
  setCurrentGroupUpdates: setCurrentGroupUpdates,
  saveGuestGroup: saveGuestGroup,
  mergeGroupUpdates: mergeGroupUpdates,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
