import _ from "lodash";
import { createSelector } from 'reselect';

const getGuests = (state) => state.guests;
const getGuestGroups = (state) => state.guestsGroups;
const getGuestGroupXrefs = (state) => state.guestsGroupXrefs;

export const getUngroupedGuests = createSelector([getGuests, getGuestGroupXrefs],	(guests, guestsGroupXrefs) => {
	return _.filter(guests, ({ guestId }) => !_.some(guestsGroupXrefs, { guestId: guestId }));
});

export const getGroupModels = createSelector([getGuestGroups, getGuests, getGuestGroupXrefs],	(guestGroups, allGuests, guestsGroupXrefs) => {
	const keyedGuests = _.keyBy(allGuests, 'guestId')
	const groupedXrefs = _.groupBy(guestsGroupXrefs, 'guestGroupId')

	const models = _.map(guestGroups, (group) => {
		const guests = _.map(groupedXrefs[group.guestGroupId], ({ guestId }) => keyedGuests[guestId]);

		return {
			...group,
			guests: guests
		}
	});

	return _.sortBy(models, 'guestGroupId');
});
