import React from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from "reactstrap/lib/ModalFooter";

class AssignGuestToGroupModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedAssignedGuestIds: {},
      selectedUngroupedGuestIds: {},
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.show && !this.props.show) {
      this.setState({
        selectedAssignedGuestIds: {},
        selectedUngroupedGuestIds: {},
      });
    }
  }

  mergeGroupUpdates = () => {
    const { selectedAssignedGuestIds, selectedUngroupedGuestIds } = this.state;
    const { selectedGuestGroup } = this.props;

    const dto = {
      guestGroupId: selectedGuestGroup.guestGroupId || 0,
      guestsToAdd: _.values(selectedUngroupedGuestIds),
      guestsToRemove: _.values(selectedAssignedGuestIds)
    };

    this.props.mergeGroupUpdates(dto, this.props.hide);
  }

  render () {
    const { selectedAssignedGuestIds, selectedUngroupedGuestIds } = this.state;
    const { show, ungroupedGuests, selectedGuestGroup } = this.props;

    if(!selectedGuestGroup) {
      return null;
    }

    return (
      <Modal isOpen={show} toggle={this.props.hide}>
        <ModalHeader>Assign Guest to Group</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              {selectedGuestGroup.guestGroupId && <>
                <h6>Assigned Guests (Check to Unassign)</h6>
                <table className="table table-bordered table-striped table-hover table-sm">
                  <thead>
                  <tr>
                    <th/>
                    <th>Guest Id</th>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    _.map(selectedGuestGroup.guests, ({ guestId, name, status }) => {
                      const isSelected = _.has(selectedAssignedGuestIds, guestId);
                      return (
                        <tr key={guestId}>
                          <td>
                            <input type="checkbox" checked={isSelected} onChange={(e) => {
                              const updatedSelectedGuestIds = { ...selectedAssignedGuestIds }

                              if(!isSelected) {
                                updatedSelectedGuestIds[guestId] = guestId;
                              } else {
                                delete updatedSelectedGuestIds[guestId];
                              }

                              this.setState({ selectedAssignedGuestIds: updatedSelectedGuestIds })
                            }}/>
                          </td>
                          <td>{guestId}</td>
                          <td>{name}</td>
                          <td>{status}</td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
              </>}

              <h6>Unassigned Guests (Check to Assign)</h6>
              <table className="table table-bordered table-striped table-hover table-sm">
                <thead>
                <tr>
                  <th/>
                  <th>Guest Id</th>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                  _.map(ungroupedGuests, ({ guestId, name, status }) => {
                    const isSelected = _.has(selectedUngroupedGuestIds, guestId);
                    return (
                      <tr key={guestId}>
                        <td>
                          <input type="checkbox" checked={isSelected} onChange={(e) => {
                            const updatedSelectedGuestIds = { ...selectedUngroupedGuestIds }

                            if(!isSelected) {
                              updatedSelectedGuestIds[guestId] = guestId;
                            } else {
                              delete updatedSelectedGuestIds[guestId];
                            }

                            this.setState({ selectedUngroupedGuestIds: updatedSelectedGuestIds })
                          }}/>
                        </td>
                        <td>{guestId}</td>
                        <td>{name}</td>
                        <td>{status}</td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>

              {_.size(selectedAssignedGuestIds) > 0 && <p>Unassign {_.size(selectedAssignedGuestIds)} Guest(s)</p>}
              {_.size(selectedUngroupedGuestIds) > 0 && <p>Assign {_.size(selectedUngroupedGuestIds)} Guest(s)</p>}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-md-12">
              <div className="pull-right">
                <button className="btn btn-primary" onClick={this.mergeGroupUpdates}>Assign</button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}


AssignGuestToGroupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  selectedGuestGroup: PropTypes.object,
  ungroupedGuests: PropTypes.array.isRequired,
  mergeGroupUpdates: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

export default AssignGuestToGroupModal;
